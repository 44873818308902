import React, { lazy, Suspense } from "react";
import AddMoreCandidate from "../Pages/BatchAllocation/AddMoreCandidate";
import BatchAllocation from "../Pages/BatchAllocation/BatchAllocation";
import BatchList from "../Pages/BatchAllocation/BatchList";
import Inquiry from "../Pages/Inquiry";
import EditBatch from "../Pages/BatchAllocation/EditBatch";
import Category from "../Pages/Category/Category";
import Candidate from "../Pages/Candidate";
import EditInquiry from "../Pages/Inquiry/EdiInquiry";
import CareerList from "../Pages/Career/CareerList";
import CenterPage from "../Pages/Center";
import CoursePage from "../Pages/Course";
import Vendor from "../Pages/Vendor/Vendor";
import Bill from "../Pages/Bill";
import BillApproval from "../Pages/Bill/BillApproval";
import Attendance from "../Pages/Attendance";
import Notification from "../Pages/Notification/notification";
import Position from "../Pages/Position/Position";
import ViewCandidate from "../Pages/Candidate/viewCandidate";
import Tot from "../Pages/Tot/Tot";
import Placement from "../Pages/Placement/Placement";
import AddPlacement from "../Pages/Placement/addPlacement";
import Accreditation from "../Pages/Accreditation";
import Hostel from "../Pages/Hostel/hostel";
import ViewHostel from "../Pages/Hostel/viewHostel";
import Class from "../Pages/Class";
import ClassDetails from "../Pages/Class/classDetails";
import Stock from "../Pages/Stock/stock";
import Inventory from "../Pages/Inventory/inventory";
import ViewInventory from "../Pages/Inventory/viewInventory";
import SalaryOverview from "../Pages/Hr/HR_Salary/salaryOverview";
import HrLayout from "../Pages/Hr Layout/Layout";
import StaffHR from "../Pages/HR_Staff";
import StaffProfile from "../Pages/HR_Staff/staffProfile";
import Setting from "../Pages/Hr/Setting/Setting";
import LateEntry from "../Pages/Hr/Setting/LateEntry";
import EditLateEntry from "../Pages/Hr/Setting/EditLateEntry";
import HolidayTemplate from "../Pages/Hr/Setting/HR_Holiday/HolidayTemplate";
import CreateHolidayTemplate from "../Pages/Hr/Setting/HR_Holiday/CreateHolidayTemplate";
import ManageStaff from "../Pages/Hr/Setting/HR_Holiday/ManageStaff";
import HrDashboard from "../Pages/Hr/HrDashboard";
import AddEditShift from "../Pages/Hr/Setting/Shift_Setting/AddEditShift";
import SalaryDetails from "../Pages/Hr/Setting/SalaryDetails/SalaryDetails";
import LeaveTemplate from "../Pages/Hr/Setting/HR_Leave_Template/index";
import AddEditLeaveTemplate from "../Pages/Hr/Setting/HR_Leave_Template/add_edit_leave_template";
import ManageLeaveStaff from "../Pages/Hr/Setting/HR_Leave_Template/manage_staff";
import ShiftSetting from "../Pages/Hr/Setting/Shift_Setting/ShiftSetting";
import ManageShiftStaff from "../Pages/Hr/Setting/Shift_Setting/ManageShiftStaff";
import SalaryStructure from "../Pages/Hr/HR_Salary/salaryStructure";
import ReviseSalary from "../Pages/Hr/HR_Salary/reviseSalary";
import EditSalary from "../Pages/Hr/HR_Salary/editSalary";
import SalaryHistory from "../Pages/Hr/HR_Salary/salaryHistory";
import Role from "../Pages/role/index";
import AddRole from "../Pages/role/addRole";
import AttendanceDetails from "../Pages/HR_Staff/AttendanceDetails";
import GlobalConfig from "../Pages/GlobalConfig";
import { moduleName } from "../Constant/common";
import Report from "../Pages/Hr/Setting/report";
import PlacementEmployer from "../Pages/PlacementEmployer/PlacementEmployer";
import EditCandidate from "../Pages/Candidate/editCandidate";
import CareerOpening from "../Pages/CareerOpening";
import BillPayment from "../Pages/Bill/BillPayment";
import ChangePassword from "../Pages/Authentication/ChangePassword";

const StaffAttendance = lazy(() => import("../Pages/Hr/StaffAttendance"));

export const routes = [
  { path: `/dashboard/`, Component: <Inquiry />, module_name: moduleName.Inquiry },
  { path: `/batchcreation`, Component: <BatchAllocation />, module_name: moduleName.BatchCreation },
  { path: `/batchlist`, Component: <BatchList />, module_name: [moduleName.BatchList,moduleName.BatchCreation] },
  { path: `/editbatch/:batchId`, Component: <EditBatch />, module_name: moduleName.BatchList },
  { path: `/addcandidate/:batchId`, Component: <AddMoreCandidate />, module_name: [moduleName.BatchList, moduleName.Candidate] },
  { path: `/editinquiry/:id`, Component: <EditInquiry />, module_name: moduleName.Inquiry },
  { path: `/candidate`, Component: <Candidate />, module_name: moduleName.Candidate },
  {
    path: `/editCandidate/:id`,
    Component: <EditCandidate />,
    module_name: moduleName.Candidate,
  },
  { path: `/center`, Component: <CenterPage />, module_name: moduleName.Center },
  { path: `/category`, Component: <Category />, module_name: moduleName.Category },
  { path: `/course`, Component: <CoursePage />, module_name: moduleName.Course },
  { path: `/career`, Component: <CareerList />, module_name: moduleName.Career },
  { path: `/vendor`, Component: <Vendor />, module_name: moduleName.Vendor },
  { path: `/bill`, Component: <Bill />, module_name: moduleName.Bill },
  { path: `/billApproval`, Component: <BillApproval />, module_name: moduleName.BillApproval },
  { path: `/attendance`, Component: <Attendance />, module_name: moduleName.Attendance },
  { path: `/allnotificationlist`, Component: <Notification />, module_name: moduleName.Notification },
  { path: `/position`, Component: <Position />, module_name: moduleName.Position },
  { path: `/stock`, Component: <Stock />, module_name: moduleName.Stock },
  { path: `/viewCandidate/:candidateId`, Component: <ViewCandidate />, module_name: [moduleName.Candidate, moduleName.Placement] },
  { path: `/tot`, Component: <Tot />, module_name: "Tot" },
  { path: `/placement`, Component: <Placement />, module_name: moduleName.Placement },
  { path: `/addPlacement/:can_id`, Component: <AddPlacement />, module_name: [moduleName.Candidate, moduleName.Placement] },
  { path: `/addPlacement/:can_id`, Component: <AddPlacement />, module_name: [moduleName.Candidate, moduleName.Placement] },
  { path: `/addPlacement/:can_id`, Component: <AddPlacement />, module_name: [moduleName.Candidate, moduleName.Placement] },
  { path: `/placementEmployer`, Component: <PlacementEmployer />, module_name: moduleName.PlacementEmployer},
  { path: `/inventory`, Component: <Inventory />, module_name: moduleName.Inventory },
  { path: `/viewInventory/:invId`, Component: <ViewInventory />, module_name: moduleName.Inventory },
  { path: `/accreditation`, Component: <Accreditation />, module_name: moduleName.Accreditation },
  { path: `/hostel`, Component: <Hostel />, module_name: moduleName.Hostel },
  { path: `/viewHostel/:hostelId`, Component: <ViewHostel />, module_name: moduleName.Hostel },
  { path: `/class`, Component: <Class />, module_name: moduleName.Class },
  { path: `/classDetails/:classId`, Component: <ClassDetails />, module_name: moduleName.Class },
  {
    path: `/staff-attendance`,
    Component: <Suspense fallback={<div>Loading...</div>}><StaffAttendance /></Suspense>,
    module_name: moduleName.StaffAttendance
  },
  {
    path: `/salaryOverview/:staffId`,
    Component: <HrLayout component={<SalaryOverview />} />,
    module_name: moduleName.Salary
  },
  {
    path: `/salaryStructure/:staffId`,
    Component: <HrLayout component={<SalaryStructure />} />,
    module_name: moduleName.Salary
  },
  {
    path: `/reviseSalaryStructure/:staffId`,
    Component: <HrLayout component={<ReviseSalary />} />,
    module_name: moduleName.Salary
  },
  {
    path: `/editSalaryStructure/:staffId`,
    Component: <HrLayout component={<EditSalary />} />,
    module_name: moduleName.Salary
  },
  {
    path: `/salaryHistory/:staffId`,
    Component: <HrLayout component={<SalaryHistory />} />,
    module_name: moduleName.Salary
  },
  {
    path: `/setting`,
    Component: <Setting />,
    module_name: moduleName.Setting
  },
  { path: `/profile`, Component: <StaffProfile />, module_name: moduleName.Public },
  { path: `/staffProfile`, Component:<StaffProfile />, module_name: moduleName.Staff },
  { path: `/staffProfile/:staffId`, Component: <HrLayout component={<StaffProfile />} />, module_name: [moduleName.Staff,moduleName.Public] },
  { path: `/HrDashboard`, Component: <HrDashboard />, module_name: moduleName.Dashboard },
  { path: `/LateEntry`, Component: <LateEntry />, module_name: moduleName.LateEntry },
  { path: `/hr_edit_late_entry`, Component: <EditLateEntry />, module_name: moduleName.LateEntry },
  { path: `/holidayTemplate`, Component: <HolidayTemplate />, module_name: moduleName.Holiday },
  { path: `/createHolidayTemplate`, Component: <CreateHolidayTemplate />, module_name: moduleName.Holiday },
  { path: `/editHolidayTemplate/:id`, Component: <CreateHolidayTemplate />, module_name: moduleName.Holiday },
  { path: `/manageStaff/:templateId`, Component: <ManageStaff />, module_name: moduleName.Holiday },
  { path: `/ShiftSetting`, Component: <ShiftSetting />, module_name: moduleName.Shift },
  { path: `/manageShiftStaff/:id`, Component: <ManageShiftStaff />, module_name: moduleName.Shift },
  { path: `/AddShift`, Component: <AddEditShift />, module_name: moduleName.Shift },
  { path: `/EditShift/:id`, Component: <AddEditShift />, module_name: moduleName.Shift },
  { path: `/role`, Component: <Role />, module_name: moduleName.RolePermission },
  { path: `/addRole`, Component: <AddRole />, module_name: moduleName.RolePermission },
  { path: `/editRole/:roleid`, Component: <AddRole />, module_name: moduleName.RolePermission },
  { path: `/viewRole/:viewRoleId`, Component: <AddRole />, module_name: moduleName.RolePermission },
  {
    path: `/SalaryDetails`,
    Component: <SalaryDetails />,
    module_name: moduleName.SalaryAccess
  },
  { path: `/leaveTemplate`, Component: <LeaveTemplate />, module_name: moduleName.Leave },
  { path: `/createLeaveTemplate`, Component: <AddEditLeaveTemplate />, module_name: moduleName.Leave },
  { path: `/editLeaveTemplate/:id`, Component: <AddEditLeaveTemplate />, module_name: moduleName.Leave },
  { path: `/manageLeaveStaff/:leaveId`, Component: <ManageLeaveStaff />, module_name: moduleName.Leave },
  {
    path: `/attendanceDetails/:staffId`,
    Component: <HrLayout component={<AttendanceDetails />} />,
    module_name: [moduleName.Staff,moduleName.Public] 
  },
  { path: `/globalConfiguration`, Component: <GlobalConfig />, module_name: [moduleName.GlobalConfiguration,moduleName.Public] },
  { path: `/staff`, Component: <StaffHR />, module_name: moduleName.Staff },
  { path: `/report`, Component: <Report />, module_name: moduleName.Report },
  { path: `/jobPosition`, Component: <CareerOpening />, module_name: moduleName.CareerOpening },
  { path: `/billPayment`, Component: <BillPayment />, module_name: moduleName.BillPayment },
  { path: `/changePassword`, Component: <ChangePassword />, module_name: moduleName.Public },
];
