import React, { useEffect, useState } from "react";
import "../../../src/hr.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  active,
  AttendanceDetails,
  DeActive,
  DeleteStaff,
  fileName,
  HR,
  ImgError,
  ImgSuccess,
  ImgTitle,
  InvalidImage,
  Profile,
  Salary,
  SalaryOverView,
  SalaryStructure,
  StaffImageMessage,
} from "../../Constant/hrLayout";
import { checkStatusCodeSuccess, getImgType, getItem, setItem } from "../../Constant/common";
import { activeInactiveStaffApi, deleteStaffApi, hrStaffImageApi } from "../../Api/HRStaffApi";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseModal from "../../BaseComponents/BaseModal";
import { StaffDeleteMessage } from "../../Constant/Center";
import { Delete, Submit } from "../../Constant";
import { BaseImageURL } from "../../Api/Service";
import { fileDownloadApi } from "../../Api/common";

const HrLayout = ({ component }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleSidebar = () => setSidebarOpen((prevState) => !prevState);
  const [staffData, setStaffData] = useState(
    () => JSON.parse(getItem("staffData")) || {}
  );
  const [staffImage, setStaffImage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(true);
  const staffId = useParams("staffId").staffId;
  const permission = JSON.parse(getItem("permissions"));
  const hasSalary = permission.some(
    (perm) => perm.parent_module_name === HR && perm.module_name === Salary
  );
  const fetchStaffImage = () => {
    setLoader(true);
    hrStaffImageApi(staffId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp)) {
          setStaffImage(resp?.data?.staff_image);
        } else {
          setIsImageLoaded(false);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    const handleStaffDataUpdate = () => {
      const updatedStaffData = JSON.parse(getItem("staffData"));
      setStaffData(updatedStaffData);
    };
    window.addEventListener("staffDataUpdated", handleStaffDataUpdate);
    fetchStaffImage();
    return () => {
      window.removeEventListener("staffDataUpdated", handleStaffDataUpdate);
    };
  }, []);

  const menuItems = [
    {
      id: 1,
      title: Profile,
      link: `/staffProfile/${staffId}`,
      ariaSelected: true,
    },
    ...(hasSalary
      ? [
    {
      id: 2,
      title: SalaryOverView,
      link: `/salaryOverview/${staffId}`,
      ariaSelected: false,
    },
    {
      id: 3,
      title: SalaryStructure,
      link: `/salaryStructure/${staffId}`,
      ariaSelected: false,
    },
  ]
  : []),
    {
      id: 4,
      title: AttendanceDetails,
      link: `/attendanceDetails/${staffId}`,
      ariaSelected: false,
    },
  ];

  const isActive = (link) => {
    return location.pathname === link || location.pathname.startsWith(link);
  };

  const handleSelectAction = (action) => {
    setLoader(true);
    if (action === active || action === DeActive) {
      const payload = { isActive: action === active };
      activeInactiveStaffApi(staffId, payload)
      .then((resp)=>{
        if (checkStatusCodeSuccess(resp.statusCode)) {
          toast.success(resp?.message);
          const newData = { ...staffData, isActive: payload.isActive };
          setStaffData(newData);
          setItem('staffData', JSON.stringify(newData));
        } else{
          toast.error(resp?.message);
        }
      })
      .catch((err)=>{
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(()=>{
        setLoader(false);
      });
    } else if (action === DeleteStaff) {
      const payload = { is_deleted: true };
      deleteStaffApi(staffId, payload)
      .then((resp)=>{
        if (checkStatusCodeSuccess(resp.statusCode)) {
          toast.success(resp?.message);
          navigate('/staff');
        } else{
          toast.error(resp?.message);
        }
      })
      .catch((err)=>{
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(()=>{
        setLoader(false);
      });
    }
  };
  const getDropdownItemClass = (status) => {
    if (status === active && staffData.isActive) {
      return "dropdown-item bg-light text-dark"; 
    } else if (status === DeActive && !staffData.isActive) {
      return "dropdown-item bg-light text-dark";
    }
    return "dropdown-item"; 
  };

  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  const downloadImage = async (imgFile, imgName) => {
    const contentType = getImgType(imgFile);
    setLoader(true);
    try {
      const response = await fileDownloadApi({
        fileName: imgFile,
      });
      const buffer = response;
      if (!buffer) {
        toast.error(InvalidImage);
        return;
      }
      const file = new Blob([buffer], { type: contentType });
      const fileUrl = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = imgName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileUrl);
      toast.success(ImgSuccess);
      setModalOpen(false);
    } catch (error) {
      toast.error(ImgError);
    } finally {
      setLoader(false); 
    }
  };
  
  return (
    <>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <header className="container-fluid">
        <div
          className="tab-pane fade active show"
          id="pills-created"
          role="tabpanel"
          aria-labelledby="pills-created-tab"
        >
          <BaseModal
            isOpen={modalDelete}
            title="Delete"
            toggler={toggleDelete}
            submit={() => handleSelectAction(DeleteStaff)}
            submitText={Delete}
            disabled={loader}
            loader={loader}
          >
            {StaffDeleteMessage}
          </BaseModal>
          <div className="card rounded bg-primary mb-0 p-2">
            <div className="row">
              <h5 className="mb-0 ml-6">
                <div className="post-header">
                  <div className="row">
                    <div className="media col-9">
                      <img
                        className="rounded-circle object-fit-cover me-2"
                        src={`${BaseImageURL}${staffImage}`}
                        onClick={() => {
                          setModalOpen(true);
                        }}
                        onError={(e) => {
                          const target = e.target;
                          target.onerror = null;
                          target.src =
                            "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png";
                        }}
                        alt={Profile}
                        height={60}
                        width={60}
                      />
                      <BaseModal
                        isOpen={modalOpen}
                        title={ImgTitle}
                        toggler={() => setModalOpen(false)}
                        submit={() => {                          
                          if (isImageLoaded) {
                          downloadImage(
                            staffImage,
                            `${staffData.id}${fileName}`
                          )
                          } else {
                            toast.error(ImgError);
                          }
                        }}
                        submitText={Submit}
                        disabled={loader}
                        loader={loader}
                      >
                        {StaffImageMessage}
                      </BaseModal>
                      <div className="media-body align-self-center">
                        <h5 className="user-name text-white mb-0">
                          {staffData?.name}
                        </h5>
                        <span className="text-white fw-normal fs-8">
                          {staffData?.id}
                        </span>
                      </div>
                    </div>
                    <div className="media col-3 justify-content-end gap-2 mt-3">
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        className="text-end rounded"
                      >
                        <DropdownToggle
                          caret
                          className="rounded-pill fw-normal"
                        >
                          {staffData.isActive === true ? active : DeActive}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            className={getDropdownItemClass(active)}
                            onClick={() => handleSelectAction(active)}
                          >
                            {active}
                          </DropdownItem>
                          <DropdownItem
                            className={getDropdownItemClass(DeActive)}
                            onClick={() => handleSelectAction(DeActive)}
                          >
                            {DeActive}
                          </DropdownItem>
                          <DropdownItem onClick={() => toggleDelete()}>
                            {DeleteStaff}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </header>

      <div className="container-fluid">
        <button
          id="hrsidebarToggle"
          onClick={toggleSidebar}
          className="d-block d-md-none"
        >
          {sidebarOpen ? "<" : ">"}
        </button>
        <div className="row">
          <nav
            id="hrsidebar"
            className={`col-xl-2 mt-2 col-lg-3 col-md-4 ${
              sidebarOpen ? "active" : "d-none d-md-block"
            }`}
          >
            <div className="email-wrap bookmark-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="md-sidebar-aside">
                    <div className="card rounded pt-3">
                      <div className="card-body hr-sidebar">
                        <div className="email-app-sidebar top-0 left-bookmark">
                          <ul className="nav main-menu" role="tablist">
                            {menuItems.map((item) => (
                              <li
                                key={item.id}
                                className={isActive(item.link) && "active"}
                              >
                                <Link
                                  to={item.link}
                                  aria-selected={isActive(item.link)}
                                >
                                  <span className="title">{item.title}</span>
                                </Link>
                              </li>
                            ))}
                            <li></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <main className="col-xl-10 rounded ps-0 mt-2 mx-0 col-lg-9 col-md-8 col-sm-12 main-content">
            {component}
          </main>
        </div>
      </div>
    </>
  );
};

export default HrLayout;
