import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../BaseComponents/BaseTable";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseModal from "../../BaseComponents/BaseModal";
import BaseInput from "../../BaseComponents/BaseInput";
import {
  RiEditFill,
  RiCheckLine,
  RiCloseLine,
  RiSearchLine,
} from "react-icons/ri";
import { Action, notFound, Submit, Update } from "../../Constant";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import { hostelLabel } from "../../Constant/Hostel/hostel";
import { validationMessages } from "../../Constant/validation";
import { hostelEdit, hostelStatusEdit, viewHostel } from "../../Api/hostel";
import { toast } from "react-toastify";
import {
  checkStatusCodeSuccess,
  extractDate,
  handleResponse,
  rendorBackButton,
  RupeesSymbol,
} from "../../Constant/common";

const ViewHostel = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [customPageSize, setCustomPageSize] = useState(50);
  const [hostelModal, setHostelModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [statusData, setStatusData] = useState();
  const [editData, setEditData] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [pageSize, setPageSize] = useState(customPageSize);
  const { hostelId } = useParams();

  const fetchData = () => {
    setLoader(true);
    viewHostel(hostelId)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          setViewData(response?.data);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validationSchema = yup.object({
    batchId: yup
      .string()
      .required(validationMessages.required(hostelLabel.batchId)),
    invoiceNo: yup
      .string()
      .required(validationMessages.required(hostelLabel.invoiceNo)),
    month: yup
      .string()
      .required(validationMessages.required(hostelLabel.month)),
    projectValued: yup
      .string()
      .required(validationMessages.required(hostelLabel.projectValued)),
    days: yup.string().required(validationMessages.required(hostelLabel.days)),
    fromDate: yup
      .string()
      .required(validationMessages.required(hostelLabel.fromDate)),
    toDate: yup
      .string()
      .required(validationMessages.required(hostelLabel.toDate)),
    claimedBill: yup
      .number()
      .required(validationMessages.required(hostelLabel.claimedBill))
      .min(1, validationMessages.greaterThan(1, hostelLabel.claimedBill)),
    received:
      isEditMode === false
        ? yup
            .number()
            .required(validationMessages.required(hostelLabel.received))
            .min(1, validationMessages.greaterThan(1, hostelLabel.received))
        : yup.string().notRequired(),
    remarks:
      isEditMode === true
        ? yup
            .string()
            .required(validationMessages.required(hostelLabel.remarks))
        : yup.string().notRequired(),
  });

  const hostelForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      batchId: isEditMode
        ? viewData?.batch?.batch_id
        : viewData?.batch?.batch_id,
      days: isEditMode ? statusData?.days : editData?.days,
      fromDate: isEditMode ? statusData?.from_date : editData?.from_date,
      toDate: isEditMode ? statusData?.to_date : editData?.to_date,
      projectValued: isEditMode
        ? statusData?.projection_valued
        : editData?.projection_valued,
      month: isEditMode ? statusData?.month_sr_no : editData?.month_sr_no,
      invoiceNo: isEditData ? editData?.bill_invoice_no || null : statusData?.bill_invoice_no,
      claimedBill: isEditData ? editData?.claimed_bill || null : statusData?.claimed_bill,
      received: isEditData ? editData?.received || null : statusData?.received,
      status: isEditMode ? statusData?.status || null : null,
      remarks: isEditMode ? statusData?.remarks || null : null,
      received_date: isEditMode ? editData?.received_date || null : null,
      submission_date:
        isEditData && editData?.submission_date
          ? new Date(editData.submission_date).toISOString().split("T")[0] || null
          : new Date().toISOString().split("T")[0],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      let payload = {
        bill_invoice_no: values.invoiceNo,
        month: values.month,
        claimed_bill: values.claimedBill,
        projection_valued: parseInt(values.projectValued),
        received: values.received,
        submission_date: values.submission_date === "" ? null : values.submission_date,
        received_date: values.received_date === "" ? null : values.received_date,
      };
      if (isEditMode) {
        payload = {
          ...payload,
          status: hostelLabel.reject,
          remarks: values.remarks,
        };
      }
      if (isEditMode) {
        hostelStatusEdit(rowId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchData();
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.resp?.data?.message || err?.message);
          })
          .finally(() => {
            resetForm();
            setBtnLoader(false);
            setHostelModal(false);
            setRowId(null);
          });
      } else {
        // Add new hostel details
        hostelEdit(rowId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp.message);
              fetchData();
            } else {
              toast.error(resp.message);
            }
          })
          .catch((err) => {
            toast.error(err?.resp?.data?.message || err?.message);
          })
          .finally(() => {
            resetForm();
            setBtnLoader(false);
            setHostelModal(false);
            setRowId(null);
          });
      }
    },
  });

  const approveBill = (row) => {
    if (
      row.claimed_bill !== null &&
      row.received_amount !== null &&
      row.bill_invoice_no !== null &&
      row.received !== null
    ) {
      setStatusId(row.id);
      setApproveModal(true);
    } else {
      toast.error(hostelLabel.validationMessage);
    }
  };

  const handleApprove = () => {
    setBtnLoader(true);
    const payload = {
      status: hostelLabel.approve,
    };
    hostelStatusEdit(statusId, payload)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          toast.success(response?.message);
          fetchData();
        } else {
          toast.error(response?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
        setApproveModal(false);
        setBtnLoader(false);
        setStatusId(null);
      });
  };

  const rejectStatus = (row) => {
    if (
      row.claimed_bill !== null &&
      row.received_amount !== null &&
      row.bill_invoice_no !== null &&
      row.received !== null
    ) {
      setStatusData(row);
      setRowId(row?.id);
      setHostelModal(true);
      setIsEditMode(true);
      setIsEditData(false);
    } else {
      toast.error(hostelLabel.validationMessage);
    }
  };

  const editHostel = (row) => {
    setIsEditData(true);
    setEditData(row);
    setRowId(row.id);
    setHostelModal(true);
    setIsEditMode(false);
  };

  const columns = useMemo(
    () => [
      {
        header: hostelLabel.month,
        accessorKey: hostelLabel.monthSrNoKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: hostelLabel.fromDate,
        accessorKey: hostelLabel.from_dateKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ cell }) => {
          return (
            <span>{`${extractDate(cell?.row?.original?.from_date)}`}</span>
          );
        },
      },
      {
        header: hostelLabel.toDate,
        accessorKey: hostelLabel.to_dateKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ cell }) => {
          return (
            <span>{`${extractDate(cell?.row?.original?.to_date)}`}</span>
          );
        },
      },
      {
        header: hostelLabel.days,
        accessorKey: hostelLabel.daysKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: hostelLabel.projectValued,
        accessorKey: hostelLabel.projection_valuedKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ cell }) => (
          <span className="fw-normal">{RupeesSymbol(cell?.row?.original?.projection_valued)}</span>
        ),
      },
      {
        header: hostelLabel.submissionDate,
        accessorKey: hostelLabel.submission_dateKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) =>
          extractDate(cell?.row?.original?.submission_date),
      },
      {
        header: hostelLabel.receivedDate,
        accessorKey: hostelLabel.received_dateKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) =>
          extractDate(cell?.row?.original?.received_date),
      },
      {
        header: hostelLabel.claimedBill,
        accessorKey: hostelLabel.claimed_billKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ cell }) => (
          <span className="fw-normal">{RupeesSymbol(cell?.row?.original?.claimed_bill)}</span>
        ),
      },
      {
        header: hostelLabel.received,
        accessorKey: hostelLabel.receivedKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ cell }) => (
          <span className="fw-normal">{RupeesSymbol(cell?.row?.original?.received)}</span>
        ),
      },
      {
        header: hostelLabel.invoiceNo,
        accessorKey: hostelLabel.bill_invoice_noKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: hostelLabel.status,
        accessorKey: hostelLabel.statusKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: hostelLabel.remarks,
        accessorKey: hostelLabel.remarksKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: Action,
        accessorKey: "action",
        enableSorting: false,
        cell: (cell) => {
          const { status } = cell.row.original;

          const isActionDisabled =
            status === hostelLabel.approve || status === hostelLabel.reject;

          return (
            <div className="d-flex justify-content-center icon">
              <span>
                <RiEditFill
                  className={`fs-5 mx-2 ${
                    isActionDisabled ? "text-muted" : "text-primary"
                  }`}
                  onClick={() =>
                    !isActionDisabled && editHostel(cell.row.original)
                  }
                  title="Edit"
                />
              </span>
              <span>
                <RiCheckLine
                  className={`fs-5 mx-2 ${
                    isActionDisabled ? "text-muted" : "text-success"
                  }`}
                  onClick={() => {
                    if (!isActionDisabled) {
                      approveBill(cell?.row?.original);
                    }
                  }}
                  title="Approve"
                />
              </span>
              <span>
                <RiCloseLine
                  className={`fs-5 mx-2 ${
                    isActionDisabled ? "text-muted" : "text-danger"
                  }`}
                  onClick={() =>
                    !isActionDisabled && rejectStatus(cell?.row?.original)
                  }
                  title="Reject"
                />
              </span>
            </div>
          );
        },
        enableColumnFilter: false,
      },
    ],
    []
  );

  const toggle = () => {
    hostelForm.resetForm();
    setHostelModal(false);
  };

  return (
    <div className="page-body">
      <div className="mx-2 mb-1 d-block d-sm-flex justify-content-between align-items-center">
        <h5 className="fw-500">{hostelLabel.hostelDetails}</h5>
        {rendorBackButton(() => navigate("/hostel"))}
      </div>
      <div className="card mx-2 mb-2">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row p-2 row-gap-2">
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.centerName}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.center?.center_name ?? handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.batchName}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.batch?.batch_id ?? handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.courseName}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.course?.course_name ?? handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.BatchStartDate}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.batch_start_date ?? handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.BatchEndDate}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.batch_end_date ?? handleResponse.nullData}
                </span>
              </div>   
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.totalProjectValue}</label>
                <span className="fs-8 fw-normal">
                  {RupeesSymbol(viewData?.projectionValue) ??
                    handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.totalClaimedAmount}</label>
                <span className="fs-8 fw-normal">
                  {RupeesSymbol(
                    viewData?.claimedValue ?? handleResponse.nullData
                  )}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.totalReceivedAmount}</label>
                <span className="fs-8 fw-normal">
                  {RupeesSymbol(
                    viewData?.receivedValue ?? handleResponse.nullData
                  )}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.EnrolledCandidate}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.enrolledCandidate ?? handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.AllocatedCandidatesForHostel}</label>
                <span className="fs-8 fw-normal">
                  {viewData?.hostelAssignedCandidate ?? handleResponse.nullData}
                </span>
              </div>
              <div className="col-xl-4 fs-8 f-500 col-lg-6 col-md-6 col-sm-12">
                <label>{hostelLabel.HostelFees}</label>
                <span className="fs-8 fw-normal">
                  {RupeesSymbol(
                    viewData?.center?.per_day_hostel_fees ??
                      handleResponse.nullData
                  )}
                </span>
              </div>
            </div>
          </div>
          </div>
          
      </div>
          <div className="mx-2">
            <h6 className="fw-500">
              {hostelLabel.monthDetails}
            </h6>
            <div className="card">
              <div className="card-body text-center">
                {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
                {!loader &&
                viewData?.hostelDetails &&
                viewData?.hostelDetails.length > 0 ? (
                  <TableContainer
                    fetchData={false}
                    columns={columns}
                    customPageSize={customPageSize}
                    data={viewData?.hostelDetails}
                    tableClass="table text-center"
                    fetchSortingData={false}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    setCustomPageSize={setCustomPageSize}
                    hasManualPagination={false}
                  />
                ) : (
                  !loader && (
                    <div className="py-4 text-center">
                      <div>
                        <RiSearchLine className="fs-2" />
                      </div>
                      <div className="mt-4">
                        <h5>{notFound.dataNotFound}</h5>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <BaseModal
            isOpen={approveModal}
            toggler={() => setApproveModal(false)}
            title={hostelLabel.approveBill}
            disabled={btnLoader}
            loader={btnLoader}
            submitText={hostelLabel.yes}
            submit={handleApprove}
          >
            <div className="text-center">
              <p>{hostelLabel.billApprove}</p>
            </div>
          </BaseModal>
          <BaseModal
            isOpen={hostelModal}
            toggler={toggle}
            title={
              isEditMode ? hostelLabel.rejectionLabel : hostelLabel.addHostelDlt
            }
            submitText={isEditMode ? Update : Submit}
            disabled={btnLoader}
            loader={btnLoader}
            submit={hostelForm.handleSubmit}
            size="md"
          >
            <div className="container-fluid">
              <div className="row">
                {/* First row: batchId, month, days */}
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.batchId}
                    type="text"
                    name="batchId"
                    className="form-control"
                    readOnly
                    disabled={true}
                    placeholder={PlaceHolderFormat(hostelLabel.batchId)}
                    value={hostelForm.values.batchId}
                    touched={hostelForm.touched.batchId}
                    error={hostelForm.errors.batchId}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.month}
                    type="text"
                    name="month"
                    className="form-control"
                    readOnly
                    disabled={true}
                    placeholder={PlaceHolderFormat(hostelLabel.month)}
                    value={hostelForm.values.month}
                    touched={hostelForm.touched.month}
                    error={hostelForm.errors.month}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.days}
                    type="number"
                    name="days"
                    className="form-control"
                    readOnly
                    disabled={true}
                    placeholder={PlaceHolderFormat(hostelLabel.days)}
                    value={hostelForm.values.days}
                    touched={hostelForm.touched.days}
                    error={hostelForm.errors.days}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
              </div>
              {/* Second row: projectValued, fromDate, toDate */}
              <div className="row">
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.fromDate}
                    type="date"
                    name="fromDate"
                    className="form-control"
                    readOnly
                    disabled={true}
                    placeholder={PlaceHolderFormat(hostelLabel.fromDate)}
                    value={hostelForm.values.fromDate}
                    touched={hostelForm.touched.fromDate}
                    error={hostelForm.errors.fromDate}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.toDate}
                    type="date"
                    name="toDate"
                    className="form-control"
                    readOnly
                    disabled={true}
                    placeholder={PlaceHolderFormat(hostelLabel.toDate)}
                    value={hostelForm.values.toDate}
                    touched={hostelForm.touched.toDate}
                    error={hostelForm.errors.toDate}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.projectValued}
                    type="text"
                    name="projectValued"
                    className="form-control"
                    readOnly
                    disabled={true}
                    placeholder={PlaceHolderFormat(hostelLabel.projectValued)}
                    value={RupeesSymbol(hostelForm.values.projectValued)}
                    touched={hostelForm.touched.projectValued}
                    error={hostelForm.errors.projectValued}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
              </div>
              <div className="custome-hr my-3"></div>
              {/* Third row: invoiceNo, claimedBill, received */}
              <div className="row">
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.invoiceNo}
                    type="text"
                    name="invoiceNo"
                    className="form-control"
                    placeholder={PlaceHolderFormat(hostelLabel.invoiceNo)}
                    value={hostelForm.values.invoiceNo}
                    touched={hostelForm.touched.invoiceNo}
                    error={hostelForm.errors.invoiceNo}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.claimedBill}
                    type="number"
                    name="claimedBill"
                    className="form-control"
                    placeholder={PlaceHolderFormat(hostelLabel.claimedBill)}
                    value={hostelForm.values.claimedBill}
                    touched={hostelForm.touched.claimedBill}
                    error={hostelForm.errors.claimedBill}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.received}
                    type="number"
                    name="received"
                    className="form-control"
                    placeholder={PlaceHolderFormat(hostelLabel.received)}
                    value={hostelForm.values.received}
                    touched={hostelForm.touched.received}
                    error={hostelForm.errors.received}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                
              </div>

              {/* Fourth row (conditionally rendered if isEditMode is true): remarks and status */}
              <div className="row">
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.submissionDate}
                    type="date"
                    name="submission_date"
                    className="form-control"
                    placeholder={PlaceHolderFormat(hostelLabel.submissionDate)}
                    value={hostelForm.values.submission_date}
                    touched={hostelForm.touched.submission_date}
                    error={hostelForm.errors.submission_date}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                <div className="col-lg-4">
                  <BaseInput
                    label={hostelLabel.receivedDate}
                    type="date"
                    name="received_date"
                    className="form-control"
                    placeholder={PlaceHolderFormat(hostelLabel.receivedDate)}
                    value={hostelForm.values.received_date}
                    touched={hostelForm.touched.received_date}
                    error={hostelForm.errors.received_date}
                    handleBlur={hostelForm.handleBlur}
                    handleChange={hostelForm.handleChange}
                  />
                </div>
                {isEditMode && (
                  <div className="col-lg-4">
                    <BaseInput
                      label={hostelLabel.remarks}
                      type="textarea"
                      name="remarks"
                      className="form-control"
                      placeholder={PlaceHolderFormat(hostelLabel.remarks)}
                      value={hostelForm.values.remarks}
                      touched={hostelForm.touched.remarks}
                      error={hostelForm.errors.remarks}
                      handleBlur={hostelForm.handleBlur}
                      handleChange={hostelForm.handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </BaseModal>
        
    </div>
  );
};

export default ViewHostel;
