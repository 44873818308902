import React, { useEffect, useState } from "react";
import { salaryEnums } from "../../../Constant/HR/staffSalary";
import { useNavigate, useParams } from "react-router-dom";
import { viewSalaryStructureApi } from "../../../Api/salaryApi";
import { checkStatusCodeSuccess, handleEditClick, RupeesSymbol } from "../../../Constant/common";
import Spinner from "../../../BaseComponents/BaseLoader";
import { salaryStructureTitle } from "../../../Constant/title";
import { RiDraftLine, RiEditBoxLine, RiHistoryLine, RiSearchLine } from "react-icons/ri";
import { Details } from "../../../Constant/HR/SalaryDetails";
import { Edit, History, notFound } from "../../../Constant";

const SalaryStructure = () => {
  document.title = salaryStructureTitle;
  const { staffId } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(null);
  const [salaryStructure, setsalaryStructure] = useState(null);

  const fetchData = () => {
    setLoader(true);
    viewSalaryStructureApi(staffId)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp.statusCode)) {
          setsalaryStructure(resp?.data);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
    handleEditClick();
  }, []);

  return (
    <>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-6">
          <h5 className="m-0">{Details.SALARYDETAILSLABEL}</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <span className="p-2 px-1 rounded-circle  text-muted cursor" onClick={() => navigate(`/reviseSalaryStructure/${staffId}`)} title={salaryEnums.REVISE} ><RiDraftLine size={20} /></span>
          <span className="p-2 px-1 rounded-circle  text-muted cursor" onClick={() => navigate(`/editSalaryStructure/${staffId}`)} title={Edit}><RiEditBoxLine size={20}  /></span>
          <span className="p-2 px-1 rounded-circle  text-muted cursor" onClick={() => navigate(`/salaryHistory/${staffId}`)} title={History}><RiHistoryLine size={20} /></span>
        </div>
      </div>
      <div className="email-wrap bookmark-wrap">
        <div className="card month-card mb-3">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {salaryStructure && (
            <div className="details">
              <div className="card-body">
                <div className="details">
                  <div className="card-body">
                    <div className="customTop">
                      <div className="row mt-2">
                        <div className="col-12">
                          <div className="row bg-light py-2 px-0 text-dark mb-2">
                            <div className="col-4">
                              <span>{salaryEnums.COMPONENTS}</span>
                            </div>
                            <div className="col-2 text-center">
                              <span>{salaryEnums.CALCULATION}</span>
                            </div>
                            <div className="col-3 text-center">
                              <span>{salaryEnums.MONTHLY_AMOUNT}</span>
                            </div>
                            <div className="col-3 text-center">
                              <span>{salaryEnums.YEARLY_AMOUNT}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-4 mt-2">
                              <h6>{salaryEnums.FIXED_EARNINGS}</h6>
                            </div>
                          </div>
                          
                          <div className="row mb-3">
                            <div className="col-4">
                              <p className="text-dark">{salaryEnums.SYSTEM_BASIC}</p>
                            </div>
                            <div className="col-2 text-center">
                              <p className="text-dark">{salaryEnums.FIXED}</p>
                            </div>
                            <div className="col-3 text-center">
                              <p className="text-dark">
                                {salaryStructure?.monthly_salary ? RupeesSymbol(salaryStructure?.monthly_salary) : null}
                              </p>
                            </div>
                            <div className="col-3 text-center">
                              <p className="text-dark">
                                {salaryStructure?.yearly_salary ? RupeesSymbol(salaryStructure?.yearly_salary) : null}
                              </p>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4">
                              <p className="text-dark">{salaryEnums.SPECIAL_ALLOWANCE}</p>
                            </div>
                            <div className="col-2 text-center">
                              <p className="text-dark">{salaryEnums.FIXED}</p>
                            </div>
                            <div className="col-3 text-center">
                              <p className="text-dark">
                                {salaryStructure?.monthly_special_allowance ? 
                                RupeesSymbol(
                                  salaryStructure?.monthly_special_allowance
                                ) : null}
                              </p>
                            </div>
                            <div className="col-3 text-center">
                              <p className="text-dark">
                                {salaryStructure?.yearly_special_allowance ? 
                                RupeesSymbol(
                                  salaryStructure?.yearly_special_allowance
                                ) : null}
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="border-bottom mb-3" />
                    <div className="customNet">
                      <div className="row mb-2">
                        <div className="col-6">
                          <h6>{salaryEnums.CTC}</h6>
                        </div>
                        <div className="col-3 text-center">
                          <p className="text-dark">
                            {salaryStructure?.total_monthly_ctc ? RupeesSymbol(salaryStructure?.total_monthly_ctc) : null}
                          </p>
                        </div>
                        <div className="col-3 text-center">
                          <p className="text-dark">{salaryStructure?.total_yearly_ctc ? RupeesSymbol(salaryStructure?.total_yearly_ctc) : null}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {((!salaryStructure && !loader) || (salaryStructure?.length < 1)) && (
          <div className="py-4 text-center">
            <div>
              <RiSearchLine className="fs-2" />
            </div>
            <div className="mt-4">
              <h5>{notFound.dataNotFound}</h5>
            </div>
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default SalaryStructure;
