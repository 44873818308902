import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BaseSelect from "../../BaseComponents/BaseSelect";
import BaseInput from "../../BaseComponents/BaseInput";
import { useFormik } from "formik";
import * as yup from "yup";
import BaseButton from "../../BaseComponents/BaseButton";
import { fileDeleteApi, fileUploadApi, listOfState } from "../../Api/common";
import {
  addPlacement,
  addSalarySlip,
  editPlacement,
  editSalarySlip,
  listOfEmployerDropdownApi,
  listOfPlacement,
  listOfPlacementCandidate,
  viewPlacementCandidate,
} from "../../Api/placement";
import TableContainer from "../../BaseComponents/BaseTable";
import { SrNo } from "../../Constant/Center";
import { RiAddLine, RiEyeFill, RiEditFill, RiSearchLine } from "react-icons/ri";
import {
  Action,
  ContactNo,
  Email,
  Loading,
  notFound,
  Search,
  Submit,
  Update,
} from "../../Constant";
import { toast } from "react-toastify";
import Spinner from "../../BaseComponents/BaseLoader";
import { BaseImageURL } from "../../Api/Service";
import BaseModal from "../../BaseComponents/BaseModal";
import {
  checkStatusCodeSuccess,
  convertDate,
  handleArrayResponse,
  handleEditClick,
  handleResponse,
  isDateValid,
  rendorBackButton,
} from "../../Constant/common";
import {
  EmploymentNatureOptions,
  Options,
  placementLable,
  placementViewLable,
} from "../../Constant/Placement/placement";
import {
  digitRegex,
  SelectPlaceHolder,
  validationMessages,
} from "../../Constant/validation";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { Label } from "reactstrap";
import { attendanceLabel } from "../../Constant/HR_Attendance/hrAttendance";
import { useSelectedCenter } from "../CenterContext";

const AddPlacement = () => {
  const { can_id } = useParams();
  const [states, setStates] = useState();
  const [placementCandidate, setPlacementCandidate] = useState(null);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customPageSize, setCustomPageSize] = useState(5);
  const [customPage, setCustomPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [searchValue, setSearchValue] = useState("");
  const [candidateData, setCandidateData] = useState([]);
  const [employmentType, setEmploymentType] = useState();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [slarySlipModal, setSlarySlipModal] = useState(false);
  const [slarySlip, setSlarySlip] = useState("");
  const [candidateId, setCandidateId] = useState(null);
  const [slipCandidateId, setSlipCandidateId] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [currentSlipId, setCurrentSlipId] = useState(null);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [candidateDetailsModal, setCandidateDetailsModal] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [company, setCompany] = useState([]); 
  const [salarySlip, setSalarySlip] = useState(null);
  const [document, setDocument] = useState(null);
  const [bankDoc, setBankDoc] = useState(null);
  const { selectedCenterPayload } = useSelectedCenter();
  const navigate = useNavigate();
  const viewCandidateDetails = (id) => {
    setLoader(true);
    viewPlacementCandidate(id)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setCandidateDetails(resp?.data);
          setCandidateDetailsModal(!candidateDetailsModal);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleCompanyChange = (field, value) => {
    const selected = companyData?.find((item) => item.id === value);
    placementForm.setFieldValue(field, value);
    placementForm.setFieldValue(
      "contact_name",
      selected?.contact_person_name || ""
    );
    placementForm.setFieldValue("contact_no", selected?.contact_no || ""); // Set contact number
    placementForm.setFieldValue("email", selected?.email || ""); // Set email
  };

  const handleSearchValueChange = (value) => {
    setSearchValue(value);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };
  let data;
  const validationSchema = yup.object().shape({
    candidateName: yup
      .string()
      .required(validationMessages.required(placementLable.candidateName)),
    employmentType: yup
      .string()
      .required(validationMessages.required(placementLable.employmentType)),
    ...(employmentType === placementLable.Business
      ? {
          businessName: yup
            .string()
            .required(validationMessages.required(placementLable.businessName)),
          businessDetails: yup
            .string()
            .required(
              validationMessages.required(placementLable.businessDetails)
            ),
          businessCertificate: yup
            .mixed()
            .required(
              validationMessages.required(placementLable.businessCertificate)
            ),
        }
      : {
          companyName: yup
            .string()
            .required(validationMessages.required(placementLable.companyName)),
          appointmentLetter: yup
            .mixed()
            .required(
              validationMessages.required(placementLable.appointmentLetter)
            ),
          employmentNature: yup
            .string()
            .required(
              validationMessages.required(placementLable.employmentNature)
            ),
          jobLocation: yup
            .string()
            .required(validationMessages.required(placementLable.jobLocation)),
          salary: yup
            .number()
            .required(validationMessages.required(placementLable.salary))
            .positive(placementLable.salaryPositive),
          joiningDate: yup
            .date()
            .required(validationMessages.required(placementLable.joiningDate)),
          closingDate: yup.date().notRequired().when("joiningDate", (joiningDate, schema) => {
              return schema.test({
                name: "closingDate",
                exclusive: false,
                message: placementLable.closingDateValidation,
                test: function (value) {
                  if (!value || !joiningDate) return true;
                  return new Date(value) > new Date(joiningDate);
                },
              });
            }),
        }),
  });

  const placementForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      candidateName: candidateData?.candidate_name || null,
      employmentType: editData?.employment_type ?? null,
      businessName: editData?.business_name ?? null,
      businessDetails: editData?.business_detail ?? null,
      businessCertificate: editData?.business_document ?? null,
      companyName: editData?.placementEmployer?.id ?? null,
      contact_name: editData?.placementEmployer?.contact_person_name ?? null,
      contact_no: editData?.placementEmployer?.contact_no ?? null,
      email: editData?.placementEmployer?.email ?? null,
      appointmentLetter: editData?.appointment_letter ?? null,
      employmentNature: editData?.employment_nature ?? null,
      jobLocation: editData?.state_id ?? null,
      salary: editData?.salary ?? null,
      joiningDate: editData?.joining_date ?? null,
      closingDate: editData?.closing_date ?? null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setBtnLoader(true);
      const payload = {
        center_id: candidateData?.center?.id,
        course_id: candidateData?.course?.id,
        batch_id: parseInt(candidateData?.batchAssign?.batch?.id),
        candidate_id: candidateData?.id,
        employment_type: values.employmentType,
        business_name: values.businessName,
        business_detail: values.businessDetails,
        business_document: values.businessCertificate,
        placement_employer_id: values.companyName,
        appointment_letter: values.appointmentLetter,
        employment_nature: values.employmentNature,
        state_id: values.jobLocation || null,
        salary: values.salary || null,
        joining_date: values.joiningDate === "" ? null : values.joiningDate,
        closing_date: values.closingDate === "" ? null : values.closingDate,
      };
      if (editId !== null) {
        editPlacement(editId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchPlacementData(null);
              setEditId(null);
              setEditData(null);
              setDocument(null);
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
          });
      } else {
        addPlacement(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp.message);
              fetchPlacementData(null);
              placementForm.resetForm();
              placementForm.setFieldValue("appointmentLetter", null);
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
          });
      }
    },
  });

  const handleEmploymentTypeChange = (field, value) => {
    placementForm.setFieldValue(field, value);
    setShowBusinessFields(value === placementLable.Business);
    setEmploymentType(value);
  };

  const [showBusinessFields, setShowBusinessFields] = useState(false);

  const editPlacementData = (id) => {
    setEditId(id);
    fetchPlacementData(id);
  };

  const fetchData = () => {
    setLoader(true);
    listOfState({})
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          data = resp?.data;
          setStates(
            data?.map((item) => ({
              value: item?.id,
              label: item?.state_name,
              id: item?.id,
            }))
          );
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      });

    listOfPlacementCandidate({
      condition: {
        id: can_id,
      },
    })
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setCandidateData(resp?.data?.listOfCandidate?.[0]);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
    listOfEmployerDropdownApi({ ...selectedCenterPayload })
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          data = resp?.data?.listOfPlacementEmployer;
          setCompany(
            data?.map((item) => ({
              value: item?.id,
              label: item?.company_name,
            }))
          );
          setCompanyData(data);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const fetchPlacementData = (id) => {
    setLoader(true);
    const payload1 = {
      order: [columnName, sortOrder],
      condition: {
        id: id,
      },
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    const payload = {
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
      condition: {
        candidate_id: parseInt(can_id),
      },
    };
    listOfPlacement(id !== null ? payload1 : payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          {
            if (id !== null) {
              setEditData(resp?.data?.listOfPlacement?.[0]);
              handleEmploymentTypeChange(
                null,
                resp?.data?.listOfPlacement?.[0].employment_type
              );
            } else {
              setPlacementCandidate(resp?.data?.listOfPlacement);
              setTotalRecords(resp.data.totalRecordsCount);
              setTotalPages(resp?.data?.totalPages);
              setTotalNumberOfRows(resp?.data?.numberOfRows);
              setCurrentPage(resp?.data?.currentPage);
            }
          }
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    fetchData();
    fetchPlacementData(null);
  }, [columnName, sortOrder, currentPage, customPageSize]);

  const slarySlipAdd = (addSlip) => {
    setCandidateId(addSlip?.candidate_id);
    setPlaceId(addSlip?.id);
    setSlarySlip(addSlip);
    setSlarySlipModal(!slarySlipModal);
  };

  const columns = useMemo(
    () => [
      {
        header: SrNo,
        accessorKey: "serial_number",
        cell: (cell) => cell.row.index + 1,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: placementLable.candidateName,
        accessorKey: placementLable.candidateKey,
        enableColumnFilter: false,
      },
      {
        header: placementLable.employmentType,
        accessorKey: placementLable.employmentTypeKey,
        enableColumnFilter: false,
      },
      {
        header: placementLable.companyName,
        accessorKey: placementLable.companyNameKey,
        enableColumnFilter: false,
      },
      {
        header: placementLable.appointmentLetter,
        accessorKey: placementLable.appointmentLetterKey,
        cell: (cell) => (
          <div className="d-flex justify-content-center">
            {cell.row.original.appointment_letter ? (
              <a
                href={`${BaseImageURL}${cell.row.original.appointment_letter}`}
                target="_blank"
                rel="noopener noreferrer"
                className={` text-success mx-2 ${
                  cell.row.original.employment_type === placementLable.Business
                    ? "disabled"
                    : ""
                }`}
              >
                {placementLable.viewLetter}
              </a>
            ) : (
              <span>{placementLable.noLetter}</span>
            )}
          </div>
        ),
        enableColumnFilter: false,
      },
      {
        header: placementLable.totalWorkingDays,
        accessorKey: placementLable.totalWorkingKey,
        enableColumnFilter: false,
      },
      {
        header: placementLable.businessName,
        accessorKey: placementLable.businessNameKey,
        enableColumnFilter: false,
      },
      {
        header: placementLable.businessDetails,
        accessorKey: placementLable.businessDetailsKey,
        enableColumnFilter: false,
      },
      {
        header: placementLable.businessCertificate,
        accessorKey: placementLable.businessCertificateKey,
        cell: (cell) => (
          <div className="d-flex justify-content-center">
            {cell.row.original.business_document ? (
              <a
                href={`${BaseImageURL}${cell.row.original.business_document}`}
                target="_blank"
                rel="noopener noreferrer"
                className={` text-success mx-2 ${
                  cell.row.original.employment_type !== placementLable.Business
                    ? "disabled"
                    : ""
                }`}
              >
                {placementLable.viewCertificate}
              </a>
            ) : (
              <span>{placementLable.noCertificate}</span>
            )}
          </div>
        ),
        enableColumnFilter: false,
      },
      {
        header: Action,
        accessorKey: "action",
        enableSorting: true,
        cell: (cell) => (
          <div className="d-flex justify-content-center icon">
            <span>
              <RiEditFill
                className="fs-5 text-primary mx-2"
                onClick={() => {
                  editPlacementData(cell?.row?.original?.id);
                  setDocument(cell?.row?.original?.appointment_letter);
                  handleEditClick();
                }}
                title="Edit"
              />
            </span>
            <span>
              <RiAddLine
                className={`fs-5 text-primary mx-2 ${
                  cell?.row?.original?.employment_type ===
                  placementLable.Business
                    ? "disabled"
                    : ""
                }`}
                title={placementViewLable.addSlipTitle}
                onClick={() => {
                  if (
                    cell?.row?.original?.employment_type !==
                    placementLable.Business
                  ) {
                    setSalarySlip(null);
                    slarySlipAdd(cell?.row?.original);
                  }
                }}
              />
            </span>
            <span>
              <RiEyeFill
                className={`fs-5 text-success mx-2 ${
                  cell?.row?.original?.employment_type ===
                  placementLable.Business
                    ? "disabled"
                    : ""
                }`}
                onClick={() => {
                  if (
                    cell?.row?.original?.employment_type !==
                    placementLable.Business
                  ) {
                    // Handle action for Add Placement
                    setSlipCandidateId(cell?.row?.original?.candidate_id);
                    viewCandidateDetails(cell?.row?.original?.id);
                  }
                }}
                title="View"
              />
            </span>
          </div>
        ),
        enableColumnFilter: false,
      },
    ],
    []
  );
  const SalarySlipColumn = useMemo(
    () => [
      {
        header: placementLable.companyName,
        accessorKey: placementLable.companyNameKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: placementLable.totalWorkingDays,
        accessorKey: placementLable.workingDayKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: placementLable.month,
        accessorKey: placementLable.monthKey,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: placementLable.salarySlip,
        accessorKey: placementLable.salarySlipKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) => (
          <div className="d-flex justify-content-center">
            {cell.row.original.salary_slip ? (
              <a
                href={`${BaseImageURL}${cell.row.original.salary_slip}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-success mx-2 "
              >
                {placementLable.viewSlip}
              </a>
            ) : (
              <span>{placementLable.noSlip}</span>
            )}
          </div>
        ),
      },
      {
        header: placementLable.bankStatement,
        accessorKey: placementLable.bankStatementKey,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) => (
          <div className="d-flex justify-content-center">
            {cell.row.original.bank_statement ? (
              <a
                href={`${BaseImageURL}${cell.row.original.bank_statement}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-success mx-2 "
              >
                {placementLable.viewBankStatement}
              </a>
            ) : (
              <span>{placementLable.noBankStatement}</span>
            )}
          </div>
        ),
      },
      {
        header: Action,
        accessorKey: "action",
        enableSorting: true,
        cell: (cell) => (
          <div className="d-flex justify-content-center icon">
            <span>
              <RiEditFill
                className="fs-5 text-primary mx-2"
                onClick={() => {
                  handleEditSlip(cell?.row?.original);
                  setSalarySlip(cell?.row?.original?.salary_slip);
                  handleEditClick();
                }}
                title="Edit"
              />
            </span>
          </div>
        ),
        enableColumnFilter: false,
      },
    ],
    []
  );

  const fileUploads = async (file) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("files", file);
      const resp = await fileUploadApi(formData);
      if (checkStatusCodeSuccess(resp?.statusCode)) {
        return resp?.data[0];
      } else {
        toast.error(resp?.message);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err?.message;
      toast.error(errorMessage);
    } finally {
      setLoader(false);
    }
  };

  const fileDelete = (fileName) => {
    const payload = {
      generatedFileNames: [fileName],
    };
    fileDeleteApi(payload)
      .then((resp) => {
        if (!checkStatusCodeSuccess(resp?.statusCode)) {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      });
  }
  const handleFileChange = async (e) => {
    const file = e?.target?.files[0];
    const fileName = e?.target?.name;
    if (file && file?.size > 1024 * 1024) {
      toast.warning(placementLable.fileSizeValidation);
      return;
    }
    try {
      const response = await fileUploads(file);
      setDocument(response);
      placementForm.setFieldValue(fileName, response);
    } catch (error) {
      toast.error(error);
    }
    if (document) {
      fileDelete(document);
    }
    
  };

  const handleSlarySlipChange = async(e, name) => {
    const file = e?.target?.files[0];
    if (file && file?.size > 1024 * 1024) {
      toast.warning(placementLable.fileSizeValidation);
      return;
    }
    try {
      const response = await fileUploads(file);
      setSalarySlip(response);
      SlipFrom.setFieldValue(name, response);
    } catch (error) {
      toast.error(error);
    }
    if (salarySlip) {
      fileDelete(salarySlip);
    }
  };
  const handleBankStatementChange = async (e, name) => {
    const file = e?.target?.files[0];
    if (file && file?.size > 1024 * 1024) {
      toast.warning(placementLable.fileSizeValidation);
      return;
    }
    try {
      const response = await fileUploads(file);
      setBankDoc(response);
      SlipFrom.setFieldValue(name, response);
    } catch (error) {
      toast.error(error);
    }
    if (bankDoc) {
      fileDelete(bankDoc);
    }
  };
  const SlipFrom = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: slarySlip?.placementEmployer?.id || null,
      month: null,
      totalWorkingDays: null,
      salarySlip: null,
      bankStatement: null,
    },
    validationSchema: yup.object().shape({
      month: yup
        .string()
        .required(validationMessages.required(placementLable.month)),
      totalWorkingDays: yup
        .number()
        .min(0, placementLable.totalWorkingDaysValidation)
        .required(validationMessages.required(placementLable.totalWorkingDays)),
      salarySlip: yup
        .string()
        .required(validationMessages.required(placementLable.salarySlip)),
      bankStatement: yup
        .string()
        .required(validationMessages.required(placementLable.bankStatement)),
    }),
    onSubmit: (values) => {
      setBtnLoader(true);
      let payload = {
        placement_employer_id: values.companyName,
        month: values.month,
        working_days: values.totalWorkingDays,
        salary_slip: values.salarySlip,
        bank_statement: values.bankStatement,
        candidate_id: slipCandidateId,
      };

      if (!isEdit) {
        // This condition ensures that candidate_id and placement_details_id are included only during addition
        payload = {
          ...payload,
          candidate_id: candidateId,
          placement_details_id: placeId,
        };
      }

      if (isEdit) {
        editSalarySlip(currentSlipId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchPlacementData(null);
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setIsEdit(null);
            setCurrentSlipId(null);
            setSlarySlipModal(false);
            setCandidateDetailsModal(false);
            setBtnLoader(false);
          });
      } else {
        addSalarySlip(payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchPlacementData(null);
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            handleArrayResponse(err);
          })
          .finally(() => {
            setBtnLoader(false);
            setSlarySlipModal(false);
            SlipFrom.resetForm();
          });
      }
    },
  });

  const handleEditSlip = (slip) => {
    setIsEdit(true);
    setCurrentSlipId(slip.id);
    SlipFrom.setValues({
      companyName: slip?.placementEmployer?.id,
      month: slip?.month,
      totalWorkingDays: slip?.working_days,
      salarySlip: slip?.salary_slip,
      bankStatement: slip?.bank_statement,
    });
    setSlarySlipModal(true);
    setSlarySlipModal(!slarySlipModal);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="page-header dash-breadcrumb py-0">
          <div className="row">
            <div className="col-6 py-2 px-3">
              <h5 className="f-w-600">{placementLable.addPlacement}</h5>
            </div>
            <div className="col-6 d-flex justify-content-end">
              {rendorBackButton(() => navigate("/placement"))}
            </div>
          </div>
        </div>
      </div>
      {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
      <div className="container-fluid default-dash">
        <div className="card p-4 rounded mb-0">
          <form onSubmit={placementForm.handleSubmit}>
            <div className="row">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <BaseSelect
                    label={placementLable.candidateName}
                    name="candidateName"
                    options={[
                      {
                        label: candidateData?.candidate_name,
                        value: candidateData?.candidate_name,
                      },
                    ]}
                    isDisabled={true}
                    handleChange={(field, value) => {
                      placementForm.setFieldValue(field, value);
                    }}
                    value={placementForm.values.candidateName}
                    touched={placementForm.touched.candidateName}
                    error={placementForm.errors.candidateName}
                    handleBlur={placementForm.handleBlur}
                    required={true}
                  />
                </div>
                <div className="col-lg-3">
                  <BaseSelect
                    label={placementLable.employmentType}
                    name="employmentType"
                    placeholder={SelectPlaceHolder(
                      placementLable.employmentType
                    )}
                    options={Options}
                    handleChange={(field, value) =>
                      handleEmploymentTypeChange(field, value)
                    }
                    value={placementForm.values.employmentType}
                    touched={placementForm.touched.employmentType}
                    error={placementForm.errors.employmentType}
                    handleBlur={placementForm.handleBlur}
                    required={true}
                  />
                </div>
                {/* Conditional rendering based on employmentType */}
                {showBusinessFields && (
                  <>
                    <div className="col-lg-3">
                      <BaseInput
                        label={placementLable.businessName}
                        type="text"
                        name="businessName"
                        className="form-control"
                        placeholder={PlaceHolderFormat(
                          placementLable.businessName
                        )}
                        value={placementForm.values.businessName}
                        touched={placementForm.touched.businessName}
                        error={placementForm.errors.businessName}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        name="businessDetails"
                        label={placementLable.businessDetails}
                        type="textarea"
                        className="form-control"
                        placeholder={PlaceHolderFormat(
                          placementLable.businessDetails
                        )}
                        value={placementForm.values.businessDetails}
                        touched={placementForm.touched.businessDetails}
                        error={placementForm.errors.businessDetails}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div>
                        <i
                          id="businessCertificate-note"
                          title={
                            placementLable.fileAllowedBussinessCrestificate
                          }
                          className="info-icon fa fa-info-circle me-2"
                          aria-hidden="true"
                        ></i>
                        <BaseInput
                          name="businessCertificate"
                          type="file"
                          accept=".jpg,.png,.doc,.docx,.pdf"
                          label={placementLable.businessCertificate}
                          handleChange={(e) =>
                            handleFileChange(e, e.target.name)
                          }
                          touched={placementForm.touched.businessCertificate}
                          error={placementForm.errors.businessCertificate}
                          handleBlur={placementForm.handleBlur}
                          required={true}
                        />
                      </div>
                      {placementForm.values.businessCertificate?.length > 0 && (
                        <span className="fw-normal">
                          <Link
                            target="_blank"
                            className="text-dark"
                            to={`${BaseImageURL}${placementForm.values.businessCertificate}`}
                          >
                            {placementLable.viewCertificate}
                          </Link>
                        </span>
                      )}
                    </div>
                  </>
                )}
                {!showBusinessFields && (
                  <>
                    <div className="col-lg-3">
                      <BaseSelect
                        label={placementLable.companyName}
                        name="companyName"
                        className="select-border"
                        options={company}
                        placeholder={SelectPlaceHolder(
                          placementLable.companyName
                        )}
                        value={placementForm.values.companyName}
                        touched={placementForm.touched.companyName}
                        error={placementForm.errors.companyName}
                        handleBlur={() =>
                          placementForm.setFieldTouched(
                            placementLable.companyName,
                            true
                          )
                        }
                        handleChange={handleCompanyChange}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        label={placementLable.contactName}
                        name="contact_name"
                        type="text"
                        className="form-control"
                        placeholder={PlaceHolderFormat(
                          placementLable.contactName
                        )}
                        value={placementForm.values.contact_name}
                        touched={placementForm.touched.contact_name}
                        error={placementForm.errors.contact_name}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        label={ContactNo}
                        name="contact_no"
                        type="text"
                        className="form-control"
                        placeholder={PlaceHolderFormat(ContactNo)}
                        value={placementForm.values.contact_no}
                        touched={placementForm.touched.contact_no}
                        error={placementForm.errors.contact_no}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        label={Email}
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder={PlaceHolderFormat(Email)}
                        value={placementForm.values.email}
                        touched={placementForm.touched.email}
                        error={placementForm.errors.email}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div>
                        <i
                          id="businessCertificate-note"
                          title={placementLable.fileAllowedAppointmentLetter}
                          className="info-icon fa fa-info-circle me-2"
                          aria-hidden="true"
                        ></i>
                        <BaseInput
                          name="appointmentLetter"
                          type="file"
                          accept=".jpg,.png,.doc,.docx,.pdf"
                          label={placementLable.appointmentLetter}
                          handleChange={(e) =>
                            handleFileChange(e, e.target.name)
                          }
                          touched={placementForm.touched.appointmentLetter}
                          error={placementForm.errors.appointmentLetter}
                          handleBlur={placementForm.handleBlur}
                          required={true}
                        />
                      </div>
                      {placementForm.values.appointmentLetter?.length > 0 && (
                        <span className="fw-normal">
                          <Link
                            target="_blank"
                            className="text-dark"
                            to={`${BaseImageURL}${placementForm.values.appointmentLetter}`}
                          >
                            {placementLable.viewLetter}
                          </Link>
                        </span>
                      )}
                    </div>
                    <div className="col-lg-3">
                      <BaseSelect
                        label={placementLable.employmentNature}
                        name="employmentNature"
                        placeholder={SelectPlaceHolder(
                          placementLable.employmentNature
                        )}
                        options={EmploymentNatureOptions}
                        handleChange={(field, value) => {
                          placementForm.setFieldValue(field, value);
                        }}
                        value={placementForm.values.employmentNature}
                        touched={placementForm.touched.employmentNature}
                        error={placementForm.errors.employmentNature}
                        handleBlur={placementForm.handleBlur}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseSelect
                        label={placementLable.jobLocation}
                        name="jobLocation"
                        options={states}
                        handleChange={(field, value) => {
                          placementForm.setFieldValue(field, value);
                        }}
                        placeholder={SelectPlaceHolder(
                          placementLable.jobLocation
                        )}
                        value={placementForm.values.jobLocation}
                        touched={placementForm.touched.jobLocation}
                        error={placementForm.errors.jobLocation}
                        handleBlur={placementForm.handleBlur}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        label={placementLable.salary}
                        name="salary"
                        type="number"
                        className="form-control"
                        placeholder={PlaceHolderFormat(placementLable.salary)}
                        value={placementForm.values.salary}
                        touched={placementForm.touched.salary}
                        error={placementForm.errors.salary}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        label={placementLable.joiningDate}
                        name="joiningDate"
                        type="date"
                        className="form-control"
                        value={placementForm.values.joiningDate}
                        touched={placementForm.touched.joiningDate}
                        error={placementForm.errors.joiningDate}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <BaseInput
                        label={placementLable.closingDate}
                        name="closingDate"
                        type="date"
                        className="form-control"
                        value={placementForm.values.closingDate}
                        touched={placementForm.touched.closingDate}
                        error={placementForm.errors.closingDate}
                        handleBlur={placementForm.handleBlur}
                        handleChange={placementForm.handleChange}
                        required={true}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 row">
                <div className="col-lg-6" />
                <div className="col-lg-6 mt-2 d-flex justify-content-end align-items-end">
                  <BaseButton
                    className="btn btn-pill"
                    type="submit"
                    loader={btnLoader}
                    disabled={btnLoader}
                    children={
                      editId !== null ? (btnLoader ? Loading : Update) : Submit
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-6 py-2 px-3">
        <h5 className="f-w-600">{placementLable.placementList}</h5>
      </div>
      <div className="card mx-3">
        <div className="card-body text-center">
          {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
          {placementCandidate && placementCandidate?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              columns={columns}
              data={searchValue ? [] : placementCandidate || []}
              isGlobalFilter={true}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              manualPagination={true}
              tableClass="table table-bordered text-center"
              onSearch={handleSearchValueChange}
              SearchPlaceholder={Search}
              fetchSortingData={handleFetchSorting}
            />
          )}
          {!loader && !placementCandidate && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>
      </div>

      <BaseModal
        isOpen={slarySlipModal}
        toggler={slarySlipAdd}
        title={
          isEdit
            ? placementViewLable.editSlipTitle
            : placementViewLable.addSlipTitle
        }
        submitText={isEdit ? Update : Submit}
        disabled={btnLoader}
        loader={btnLoader}
        submit={SlipFrom.handleSubmit}
      >
        <div className="row">
          <div className="col-lg-6">
            <BaseSelect
              label={placementLable.companyName}
              name="companyName"
              options={company}
              isDisabled={true}
              handleChange={(field, value) => {
                SlipFrom.setFieldValue(field, value);
              }}
              value={SlipFrom.values.companyName}
              touched={SlipFrom.touched.companyName}
              error={SlipFrom.errors.companyName}
              handleBlur={SlipFrom.handleBlur}
              disabled={true}
            />
          </div>
          <div className="col-lg-6">
            <Label htmlFor="month">{placementLable.month}</Label>
            <DatePicker
              id="month"
              selected={
                SlipFrom.values.month
                  ? isDateValid(SlipFrom.values.month)
                    ? convertDate(SlipFrom.values.month)
                    : new Date(`2024-${SlipFrom.values.month}-01`)
                  : null
              }
              placeholderText={PlaceHolderFormat(placementLable.month)}
              onChange={(date) =>
                SlipFrom.setFieldValue(
                  placementLable.monthKey,
                  date && format(date, "MMMM")
                )
              }
              dateFormat="MMMM"
              showMonthYearPicker
              className={`form-control ${
                SlipFrom.errors.month && "is-invalid"
              }`}
              onBlur={SlipFrom.handleBlur}
            />
            {SlipFrom.errors.month && SlipFrom.errors.month ? (
              <div className="invalid-feedback d-block">
                {SlipFrom.errors.month}
              </div>
            ) : null}
          </div>

          <div className="col-lg-6">
            <BaseInput
              label={placementLable.totalWorkingDays}
              type="number"
              min={0}
              name="totalWorkingDays"
              className="form-control"
              placeholder={PlaceHolderFormat(placementLable.totalWorkingDays)}
              value={SlipFrom.values.totalWorkingDays}
              touched={SlipFrom.touched.totalWorkingDays}
              handleChange={(e) => {
                if (
                  e?.target?.value?.length <= 3 &&
                  digitRegex.test(e.target.value)
                ) {
                  SlipFrom.handleChange(e);
                }
              }}
              error={SlipFrom.errors.totalWorkingDays}
              handleBlur={SlipFrom.handleBlur}
            />
          </div>
          <div className="col-lg-6">
            <div>
              <i
                id="salarySlip"
                title={placementLable.fileAllowedSalarySlip}
                className="info-icon fa fa-info-circle me-2"
                aria-hidden="true"
              ></i>
              <BaseInput
                name="salarySlip"
                label={placementLable.salarySlip}
                className="form-control"
                type="file"
                accept=".jpg,.jpeg,.png,.pdf.doc,.docx"
                handleChange={(e) => handleSlarySlipChange(e, e.target.name)}
                touched={SlipFrom.touched.salarySlip}
                error={SlipFrom.errors.salarySlip}
                handleBlur={SlipFrom.handleBlur}
              />
            </div>
            {SlipFrom.values.salarySlip?.length > 0 && (
              <span className="fw-normal">
                <Link
                  target="_blank"
                  className="text-dark"
                  to={`${BaseImageURL}${SlipFrom.values.salarySlip}`}
                >
                  {placementLable.viewSlip}
                </Link>
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <div>
              <i
                id="bankStatement"
                title={placementLable.fileAllowedSalarySlip}
                className="info-icon fa fa-info-circle me-2"
                aria-hidden="true"
              ></i>
              <BaseInput
                name="bankStatement"
                label={placementLable.bankStatement}
                className="form-control"
                type="file"
                accept=".jpg,.jpeg,.png,.pdf.doc,.docx"
                handleChange={(e) =>
                  handleBankStatementChange(e, e.target.name)
                }
                touched={SlipFrom.touched.bankStatement}
                error={SlipFrom.errors.bankStatement}
                handleBlur={SlipFrom.handleBlur}
              />
            </div>
            {SlipFrom.values.bankStatement?.length > 0 && (
              <span className="fw-normal">
                <Link
                  target="_blank"
                  className="text-dark"
                  to={`${BaseImageURL}${SlipFrom.values.bankStatement}`}
                >
                  {placementLable.viewBankStatement}
                </Link>
              </span>
            )}
          </div>
        </div>
      </BaseModal>

      {candidateDetailsModal && (
        <BaseModal
          size="lg"
          isOpen={candidateDetailsModal}
          toggler={() => setCandidateDetailsModal(false)}
          title={placementViewLable.CandidatePlacementDetails}
          submitText="Close"
          hasSubmitButton={false}
        >
          <div className="p-6">
            {candidateDetails ? (
              <>
                <div className="section-content pt-0">
                  <div className="section-title p-1 bg-light text-dark fs-7 font-weight-bold ">
                    <p className="m-0">{placementLable.personaldetails}</p>
                  </div>
                  <div className="row p-2">
                    <div className="col-6 d-flex">
                      <p className="fs-8 m-0 fw-medium">
                        {placementViewLable.candidateName}
                      </p>
                      <p className="fs-8 m-0">
                        {candidateDetails?.candidate?.candidate_name ||
                          attendanceLabel.noData}
                      </p>
                    </div>
                    <div className="col-6 d-flex">
                      <p className="fs-8 m-0 fw-medium">
                        {placementViewLable.employmentType}
                      </p>
                      <p className="fs-8 m-0">
                        {candidateDetails?.employment_type ||
                          attendanceLabel.noData}
                      </p>
                    </div>
                    <div className="col-6 d-flex">
                      <p className="fs-8 m-0 fw-medium">
                        {placementViewLable.contactNumber}
                      </p>
                      <p className="fs-8 m-0">
                        {candidateDetails?.candidate?.contact_no ||
                          attendanceLabel.noData}
                      </p>
                    </div>
                    <div className="col-6 d-flex">
                      <p className="fs-8 m-0 fw-medium">
                        {placementViewLable.emailAddress}
                      </p>
                      <p className="fs-8 m-0">
                        {candidateDetails?.candidate?.email ||
                          attendanceLabel.noData}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="section-content salaryslip">
                  <div className="section-title p-1 bg-light text-dark fs-7 font-weight-bold ">
                    <p className="m-0">{placementViewLable.slarySlipDetails}</p>
                  </div>

                  <div className="card-body text-center">
                    {loader && (
                      <Spinner attrSpinner={{ className: "loader-2" }} />
                    )}
                    {Array.isArray(candidateDetails?.salarySlipDetail) && (
                      <>
                        <TableContainer
                          fetchData={false}
                          columns={SalarySlipColumn}
                          data={candidateDetails?.salarySlipDetail}
                          tableClass="table table-bordered text-center"
                          fetchSortingData={false}
                          setCustomPageSize={setCustomPage}
                          customPage={customPage}
                          hasManualPagination={false}
                          customPageSize={1000}
                          manualPagination={true}
                        />
                      </>
                    )}
                    {!loader && !candidateDetails?.salarySlipDetail && (
                      <div className="py-4 text-center">
                        <div>
                          <i className="ri-search-line display-5 text-success"></i>
                        </div>
                        <div className="mt-4">
                          <h5>{notFound.dataNotFound}</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <p>{handleResponse.dataNotFound}</p>
            )}
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default AddPlacement;
