import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TableContainer from "../../BaseComponents/BaseTable";
import BaseModal from "../../BaseComponents/BaseModal";
import BaseInput from "../../BaseComponents/BaseInput";
import {
  RiEditFill,
  RiCloseLine,
  RiCheckLine,
  RiSearchLine,
} from "react-icons/ri";
import { classEnums } from "../../Constant/Class/class";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import "../../assets/css/notification.css";
import {
  getClassIdFromSessionStorage,
  getBatchIdFromSessionStorage,
  handleResponse,
  extractDate,
  RupeesSymbol,
  rendorBackButton,
  checkStatusCodeSuccess,
} from "../../Constant/common";
import {
  editMilestoneApi,
  editStatusApi,
  listOfMilestoneApi,
  viewClassApi,
} from "../../Api/ClassApi";
import Spinner from "../../BaseComponents/BaseLoader";
import { toast } from "react-toastify";
import { Search, Submit, Update, notFound } from "../../Constant";
import { useNavigate } from "react-router-dom";
import { validationMessages } from "../../Constant/validation";
import { useSelectedCenter } from "../CenterContext";

const ClassDetails = () => {
  const navigate = useNavigate();
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [classDetails, setClassDetails] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [approveModal, setApproveModal] = useState(false);
  const [milestoneModal, setMilestoneModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [milestoneData, setMilestoneData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [milestoneId, setMilestoneId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchMilestoneData, setSearchMilestoneData] = useState([]);
  const [milestone, setMilestone] = useState(null);
  const classIdFromSession = getClassIdFromSessionStorage();
  const batchIdFromSession = getBatchIdFromSessionStorage();
  const currentDate = new Date().toISOString().split("T")[0];
  const selectedCenter = useSelectedCenter();

  useEffect(() => {
    fetchMilestone(null);
  }, [currentPage, customPageSize, columnName, sortOrder, searchValue]);

  useEffect(() => {
    fetchClassDetails(classIdFromSession);
  }, []);

  const fetchClassDetails = (classIdFromSession) => {
    setLoader(true);
    viewClassApi(classIdFromSession)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setClassDetails(resp?.data);
          fetchMilestone(null);
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchMilestone = async (milestoneId) => {
    setLoader(true);
    const payload = {
      center_id: selectedCenter.selectedCenter,
      condition: {
        batch_id: Number(batchIdFromSession),
      },
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    const milestonePayload = {
      condition: {
        id: milestoneId,
        batch_id: Number(batchIdFromSession),
      },
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    listOfMilestoneApi(milestoneId !== null ? milestonePayload : payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          if (milestoneId !== null) {
            setEditData(resp?.data?.listOfMilestone[0]);
          } else {
            setMilestoneData(resp?.data?.listOfMilestone);
            setSearchMilestoneData(resp?.data?.listOfMilestone);
            setTotalPages(resp?.data?.totalPages);
            setTotalRecords(resp?.data?.totalRecordsCount);
            setTotalNumberOfRows(resp?.data?.numberOfRows);
            setCurrentPage(resp?.data?.currentPage);
          }
        } else {
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        setSearchMilestoneData([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const validationSchema = yup.object({
    claimed_amount: yup
      .string()
      .required(validationMessages.required(classEnums.CLAIMED_AMOUNT)),
    received_amount: yup
      .string()
      .required(validationMessages.required(classEnums.RECEIVED_AMOUNT)),
    bill_invoice: yup
      .string()
      .required(validationMessages.required(classEnums.INVOICE_NO)),
    submission_date: yup
      .string()
      .required(validationMessages.required(classEnums.SUBMISSION_DATE)),
  });

  const newValidationSchema = yup.object({
    claimed_amount: yup
      .string()
      .required(validationMessages.required(classEnums.CLAIMED_AMOUNT)),
    received_amount: yup
      .string()
      .required(validationMessages.required(classEnums.RECEIVED_AMOUNT)),
    bill_invoice: yup
      .string()
      .required(validationMessages.required(classEnums.INVOICE_NO)),
    remarks: yup
      .string()
      .required(validationMessages.required(classEnums.REMARKS)),
    submission_date: yup
      .string()
      .required(validationMessages.required(classEnums.SUBMISSION_DATE)),
  });

  const handleFetchData = (page) => {
    setCurrentPage(page);
  };

  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const milestoneForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      total_uniform: RupeesSymbol(editData?.total_uniform) || null,
      total: RupeesSymbol(editData?.total_milestone) || null,
      claimed_amount: editData?.claimed_amount || null,
      received_amount: editData?.received_amount || null,
      bill_invoice: editData?.bill_invoice || null,
      submission_date: editData?.submission_date || currentDate,
      milestone_status: editData?.milestone_status || null,
      remarks: editData?.remarks || null,
    },

    validationSchema: isEditMode ? newValidationSchema : validationSchema,
    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      let payload = {
        claimed_amount: Number(values.claimed_amount),
        received_amount: Number(values.received_amount),
        bill_invoice: String(values.bill_invoice),
        submission_date: values.submission_date === "" ? null : values.submission_date,
      };
      if (isEditMode) {
        payload = {
          ...payload,
          bill_status: classEnums.REJECTED,
          remarks: values.remarks,
        };
        editStatusApi(milestoneId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchMilestone(null);
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.resp?.data?.message || err?.message);
          })
          .finally(() => {
            setBtnLoader(false);
            resetForm();
            setMilestoneModal(false);
            setMilestoneId(null);
          });
      }
      if (!isEditMode) {
        editMilestoneApi(milestoneId, payload)
          .then((resp) => {
            if (checkStatusCodeSuccess(resp?.statusCode)) {
              toast.success(resp?.message);
              fetchMilestone(null);
            } else {
              toast.error(resp?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.resp?.data?.message || err?.message);
          })
          .finally(() => {
            setBtnLoader(false);
            resetForm();
            setMilestoneModal(false);
          });
      }
    },
  });

  const approveBill = (row) => {
    if (
      row.claimed_amount !== null &&
      row.received_amount !== null &&
      row.bill_invoice !== null &&
      row.submission_date !== null
    ) {
      setMilestoneId(row.id);
      setIsEditMode(true);
      setApproveModal(true);
    } else {
      toast.error(classEnums.ERROR_MESSAGE);
    }
  };

  const handleApprove = () => {
    setBtnLoader(true);
    const payload = {
      bill_status: classEnums.APPROVED,
    };
    editStatusApi(milestoneId, payload)
      .then((response) => {
        if (checkStatusCodeSuccess(response?.statusCode)) {
          toast.success(response?.message);
          fetchMilestone(null);
        } else {
          toast.error(response?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
      })
      .finally(() => {
        setLoader(false);
        setApproveModal(false);
        setBtnLoader(false);
        setMilestoneId(null);
      });
  };
  const rejectStatus = (row) => {
    if (
      row.claimed_amount !== null &&
      row.received_amount !== null &&
      row.bill_invoice !== null &&
      row.submission_date !== null
    ) {
      setMilestoneId(row.id);
      fetchMilestone(row.id);
      setMilestoneModal(true);
      setIsEditMode(true);
    } else {
      toast.error(classEnums.ERROR_MESSAGE);
    }
  };

  const addMilestone = (id, milestone) => {
    fetchMilestone(id);
    setMilestoneId(id);
    setMilestone(milestone);
    setMilestoneModal(true);
    setIsEditMode(false);
  };

  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setSearchValue(value);
      setCurrentPage(1);
    }
  };

  const milestoneColumns = useMemo(
    () => [
    {
      header: classEnums.MILESTONE,
      accessorKey: "milestone",
      enableColumnFilter: false,
    },
    {
      header: classEnums.MILESTONE_AMOUNT,
      accessorKey: "milestone_amount",
      enableColumnFilter: false,
      cell: ({ cell }) => (
        <span>{RupeesSymbol(cell?.row?.original?.milestone_amount)}</span>
      ),
    },
    {
      header: classEnums.UNIFORM,
      accessorKey: "total_uniform",
      enableColumnFilter: false,
      cell: ({ cell }) => (
        <span>{RupeesSymbol(cell?.row?.original?.total_uniform)}</span>
      ),
    },
    {
      header: classEnums.TDS,
      accessorKey: "tds",
      enableColumnFilter:false,
    },
    {
      header: classEnums.TDS_AMOUNT,
      accessorKey: "tds_amount",
      enableColumnFilter:false,
    },
    {
      header: classEnums.TOTAL,
      accessorKey: "total_milestone",
      enableColumnFilter: false,
      cell: ({ cell }) => (
        <span>{RupeesSymbol(cell?.row?.original?.total_milestone)}</span>
      ),
    },
    {
      header: classEnums.CLAIMED_AMOUNT,
      accessorKey: "claimed_amount",
      enableColumnFilter: false,
      cell: ({ cell }) => (
        <span>{RupeesSymbol(cell?.row?.original?.claimed_amount)}</span>
      ),
    },
    {
      header: classEnums.RECEIVED_AMOUNT,
      accessorKey: "received_amount",
      enableColumnFilter: false,
      cell: ({ cell }) => (
        <span>{RupeesSymbol(cell?.row?.original?.received_amount)}</span>
      ),
    },
    {
      header: classEnums.INVOICE_NO,
      accessorKey: "bill_invoice",
      enableColumnFilter: false,
    },
    {
      header: classEnums.SUBMISSION_DATE,
      accessorKey: "submission_date",
      enableColumnFilter: false,
      cell: ({ cell }) => {
        return (
          <span>{`${extractDate(cell?.row?.original?.submission_date)}`}</span>
        );
      },
    },
    {
      header: classEnums.STATUS,
      accessorKey: "bill_status",
      enableColumnFilter: false,
    },
    {
      header: classEnums.REMARKS,
      accessorKey: "remarks",
      enableColumnFilter: false,
    },
    {
      header: classEnums.ACTION,
      accessorKey: "action",
      enableColumnFilter: false,
      cell: ({ cell }) => {
        const isActionDisabled =
          cell.row.original.bill_status === classEnums.APPROVED ||
          cell.row.original.bill_status === classEnums.REJECTED;

        return (
          <div className="d-flex justify-content-center">
            <RiEditFill
              className={`fs-5 mx-2 ${
                isActionDisabled ? "text-muted" : "text-primary"
              }`}
              onClick={() =>
                !isActionDisabled &&
                addMilestone(cell.row.original.id, cell.row.original.milestone)
              }
              title={classEnums.ADD_MILESTONE}
            />
            <RiCheckLine
              className={`fs-5 mx-2 ${
                isActionDisabled ? "text-muted" : "text-success"
              }`}
              onClick={() =>
                !isActionDisabled && approveBill(cell.row.original)
              }
              title={classEnums.APPROVED}
            />
            <RiCloseLine
              className={`fs-5 mx-2 ${
                isActionDisabled ? "text-muted" : "text-danger"
              }`}
              onClick={() =>
                !isActionDisabled && rejectStatus(cell.row.original)
              }
              title={classEnums.REJECTED}
            />
          </div>
        );
      },
    },
  ],
  []
);

  return (
    <>
      <div className="mx-3 d-flex justify-content-between align-items-center mb-1">
        <h5 className="mx-1">{classEnums.CLASS_DETAILS}</h5>
        {rendorBackButton(() => navigate("/class"))}
      </div>

      <div className="container-fluid">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <div className="card mx-2 section mb-3 p-2">
          {classDetails && (
            <div className="row p-2">
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.CENTER_NAME}:</span>
                  {classDetails?.viewClass?.batch?.center?.center_name ??
                    handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.BATCH_ID}:</span>
                  {classDetails?.viewClass?.batch?.batch_id ??
                    handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.COURSE_NAME}:</span>
                  {classDetails?.viewClass?.batch?.course?.course_name ??
                    handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums.BATCH_START_DATE}:
                  </span>
                  {extractDate(classDetails?.viewClass?.batch?.start_date) ??
                    handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.BATCH_END_DATE}:</span>
                  {extractDate(classDetails?.viewClass?.batch?.end_date) ??
                    handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.BATCH_STATUS}:</span>
                  {classDetails?.viewClass?.is_active ??
                    handleResponse.nullData}
                </p>
              </div>
              
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.TOTAL_HOURS}:</span>
                  {classDetails?.viewClass?.batch?.course?.course_hours ??
                    handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.RATE_PER_HOUR}:</span>
                  {RupeesSymbol(
                    classDetails?.viewClass?.batch?.course?.course_rate
                  ) ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums?.ENROLLED_CANDIDATES}:
                  </span>
                  {classDetails?.enrolled ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums.ASSESSED_CANDIDATES}:
                  </span>
                  {classDetails?.assessment ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums.PLACED_CANDIDATES}:
                  </span>
                  {classDetails?.placement ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums.CERTIFIED_CANDIDATES}:
                  </span>
                  {classDetails?.certified ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums.DROPOUT_CANDIDATES}:
                  </span>
                  {classDetails?.dropOut ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">
                    {classEnums.PENDING_CANDIDATES}:
                  </span>
                  {classDetails?.pending ?? handleResponse.nullData}
                </p>
              </div>
              <div className="col-4">
                <p className="fs-8">
                  <span className="fw-500">{classEnums.TOTAL_MILESTONE}:</span>
                  {RupeesSymbol(classDetails?.total_milestone_value) ??
                    handleResponse.nullData}
                </p>
              </div>
            </div>
          )}
          {!loader && !classDetails && (
            <div className="py-4 text-center">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
        </div>

        <div className="mx-2">
          <h5 className="bg-light text-dark">{classEnums.MILESTONE}</h5>
        </div>
        <div className="card mx-2">
          <div className="card-body text-center">
            {milestoneData && milestoneData?.length > 0 && (
              <TableContainer
                totalPages={totalPages}
                totalRecords={totalRecords}
                totalNumberOfRows={totalNumberOfRows}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                fetchData={handleFetchData}
                columns={milestoneColumns}
                data={searchValue ? searchMilestoneData : milestoneData || []}
                isGlobalFilter={true}
                customPageSize={customPageSize}
                setCustomPageSize={setCustomPageSize}
                manualPagination={true}
                tableClass="table table-bordered text-center"
                onSearch={handleSearchValueChange}
                SearchPlaceholder={Search}
                fetchSortingData={handleFetchSorting}
                manualFiltering={true}
              />
            )}
            {!loader && !milestoneData && (
              <div className="py-4 text-center">
                <div>
                  <RiSearchLine className="fs-2" />
                </div>
                <div className="mt-4">
                  <h5>{notFound.dataNotFound}</h5>
                </div>
              </div>
            )}
          </div>
        </div>

        <BaseModal
          isOpen={approveModal}
          toggler={() => setApproveModal(false)}
          title={classEnums.BILL_TITLE}
          submitText={classEnums.YES}
          submit={handleApprove}
          isDisabled={btnLoader}
          loader={btnLoader}
        >
          <div className="text-left">
            <p>{classEnums.billApprove}</p>
          </div>
        </BaseModal>

        <BaseModal
          isOpen={milestoneModal}
          title={
            isEditMode ? classEnums.EDIT_MILESTONE : classEnums.ADD_MILESTONE
          }
          toggler={() => {
            setMilestoneModal(false);
            milestoneForm.resetForm();
          }}
          size="md"
          submitText={isEditMode ? Update : Submit}
          submit={milestoneForm.handleSubmit}
          isDisabled={btnLoader}
          loader={btnLoader}
        >
          <form onSubmit={milestoneForm.handleSubmit}>
            <div className="row">
              {milestone === classEnums.MILESTONE_1 && (
                <div className="col-6">
                  <BaseInput
                    name="total_uniform"
                    label={classEnums.UNIFORM}
                    disabled
                    placeholder={PlaceHolderFormat(classEnums.UNIFORM)}
                    value={milestoneForm.values.total_uniform}
                    handleChange={milestoneForm.handleChange}
                    handleBlur={milestoneForm.handleBlur}
                    error={milestoneForm.errors.total_uniform}
                    touched={milestoneForm.touched.total_uniform}
                  />
                </div>
              )}
              <div className="col-6">
                <BaseInput
                  name="total"
                  label={classEnums.TOTAL}
                  disabled
                  placeholder={PlaceHolderFormat(classEnums.TOTAL)}
                  value={milestoneForm.values.total}
                  handleChange={milestoneForm.handleChange}
                  handleBlur={milestoneForm.handleBlur}
                  error={milestoneForm.errors.total}
                  touched={milestoneForm.touched.total}
                />
              </div>
              <div className="custome-hr my-3"></div>
              <div className="col-6">
                <BaseInput
                  name="claimed_amount"
                  label={classEnums.CLAIMED_AMOUNT}
                  placeholder={PlaceHolderFormat(classEnums.CLAIMED_AMOUNT)}
                  value={milestoneForm.values.claimed_amount}
                  handleChange={milestoneForm.handleChange}
                  handleBlur={milestoneForm.handleBlur}
                  error={milestoneForm.errors.claimed_amount}
                  touched={milestoneForm.touched.claimed_amount}
                />
              </div>
              <div className="col-6">
                <BaseInput
                  name="received_amount"
                  label={classEnums.RECEIVED_AMOUNT}
                  placeholder={PlaceHolderFormat(classEnums.RECEIVED_AMOUNT)}
                  value={milestoneForm.values.received_amount}
                  handleChange={milestoneForm.handleChange}
                  handleBlur={milestoneForm.handleBlur}
                  error={milestoneForm.errors.received_amount}
                  touched={milestoneForm.touched.received_amount}
                />
              </div>
              <div className="col-6">
                <BaseInput
                  name="bill_invoice"
                  label={classEnums.INVOICE_NO}
                  placeholder={PlaceHolderFormat(classEnums.INVOICE_NO)}
                  value={milestoneForm.values.bill_invoice}
                  handleChange={milestoneForm.handleChange}
                  handleBlur={milestoneForm.handleBlur}
                  error={milestoneForm.errors.bill_invoice}
                  touched={milestoneForm.touched.bill_invoice}
                />
              </div>
              <div className="col-6">
                <BaseInput
                  name="submission_date"
                  type="date"
                  label={classEnums.SUBMISSION_DATE}
                  placeholder={PlaceHolderFormat(classEnums.SUBMISSION_DATE)}
                  value={milestoneForm.values.submission_date}
                  handleChange={milestoneForm.handleChange}
                  handleBlur={milestoneForm.handleBlur}
                  error={milestoneForm.errors.submission_date}
                  touched={milestoneForm.touched.submission_date}
                />
              </div>
              {isEditMode && (
                <div className="col-6">
                  <BaseInput
                    type="textarea"
                    name="remarks"
                    label={classEnums.REMARKS}
                    placeholder={PlaceHolderFormat(classEnums.REMARKS)}
                    value={milestoneForm.values.remarks}
                    handleChange={milestoneForm.handleChange}
                    handleBlur={milestoneForm.handleBlur}
                    error={milestoneForm.errors.remarks}
                    touched={milestoneForm.touched.remarks}
                  />
                </div>
              )}
            </div>
          </form>
        </BaseModal>
      </div>
    </>
  );
};

export default ClassDetails;
